import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'default',
      title: 'Легкозайм - Главная',
      auth: false,
      name: 'Главная'
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      layout: 'default',
      title: 'Легкозайм - Главная',
      auth: false,
      name: 'Главная'
    },
    component: () =>
      import(/* webpackChunkName: 'about' */ '../views/AboutView.vue')
  },
  {
    path: '/pre-confirmation',
    name: 'pre-confirmation',
    meta: {
      layout: 'default',
      title: 'Легкозайм - Главная',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/CheckUserOrder.vue')
  },
  {
    path: '/signup/:phone',
    name: 'signup',
    meta: {
      layout: 'default',
      title: 'Легкозайм - Подтверждение телефона',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/SignupView.vue')
  },
  {
    path: '/pay/callback',
    name: 'pay/callback',
    meta: {
      layout: 'default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/PayCallback.vue')
  },
  {
    path: '/pay/loanpay',
    name: 'pay/loanpay',
    meta: {
      layout: 'default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/LoanPay.vue')
  },
  {
    path: '/confirm-debt',
    name: 'confirm-debt',
    meta: {
      layout: 'default',
      title: '',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/ConfirmDebt.vue')
  },
  {
    path: '/loan',
    name: 'loan',
    meta: {
      layout: 'dashboard',
      title: 'Легкозайм - Текущий займ',
      auth: true,
      name: 'Текущий займ'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/LoanView.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      layout: 'dashboard',
      title: 'Легкозайм - История займов',
      auth: true,
      name: 'История займов'
    },
    component: () =>
      import(/* webpackChunkName: 'history' */ '../views/HistoryView.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {
      layout: 'dashboard',
      title: 'Легкозайм - Документы',
      auth: true,
      name: 'Документы'
    },
    component: () =>
      import(/* webpackChunkName: 'documents' */ '../views/DocumentsView.vue')
  },
  {
    path: '/files',
    name: 'files',
    meta: {
      layout: 'dashboard',
      title: 'Легкозайм - Файлы',
      auth: true,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'files' */ '../views/FilesView.vue')
  },
  {
    path: '/qr',
    name: 'qr',
    meta: {
      layout: 'default',
      title: 'Легкозайм - Файлы',
      auth: false,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'qr' */ '../views/QrView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentQuery = { ...to.query }
  const userId = from.query.user_id || to.query.user_id
  const tokenFromCRM = from.query.token || to.query.token
  const fromCRM = from.query.from_crm || to.query.from_crm
  const requireAuth = to.matched.some((record) => record.meta.auth)
  const token = store.getters.TOKEN || localStorage.getItem('token') || null

  const newQuery = { ...currentQuery }

  if (fromCRM) {
    newQuery.from_crm = fromCRM
  }

  if (tokenFromCRM) {
    newQuery.token = tokenFromCRM
  }

  if (requireAuth) {
    if (token?.length < 2) {
      store.dispatch('addNotification', {
        message: 'Токен доступа истёк, пройдите авторизацию повторно'
      })
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
