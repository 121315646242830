<template>
  <component :is="layout">
    <router-view />
  </component>
  <v-notification />
</template>

<script>
import { mapActions } from 'vuex'

import DefaultLayout from '@/layouts/DefaultLayout'
import DashboardLayout from '@/layouts/DashboardLayout'
import VNotification from '@/components/UI/v-notification.vue'
import { useI18n } from 'vue-i18n';
export default {
  components: {
    DefaultLayout,
    DashboardLayout,
    VNotification
  },
  computed: {
    layout () {
      return (this.$route.meta?.layout || 'default') + '-layout'
    }
  },
  methods: {
    ...mapActions(['GET_MORATORIUM_STATUS', 'addNotification'])
  },
  async mounted () {
    const token = localStorage.getItem('token')

    if (token?.length > 10) {
      this.$store.commit('SET_TOKEN', token)
    }
  },
  beforeCreate() {
    const { t } = useI18n({ useScope: 'global' });

    this.$options.provide ={
      't': t
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
