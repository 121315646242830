<template>
  <div
    v-if="notifications.length"
    class="notifications-container"
  >
    <div
      v-for="(notification, idx) in notifications"
      :key="idx"
      ref="notification"
      class="notification"
    >
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { gsap } from 'gsap'

export default {
  name: 'VNotification',
  computed: {
    ...mapGetters(['getNotifications']),
    notifications () {
      return this.getNotifications
    }
  },
  mounted () {
    this.$store.subscribe(this.animateNotifications)
  },
  methods: {
    ...mapActions(['removeNotification']),
    animateNotifications () {
      this.$nextTick(() => {
        const notifications = this.$refs.notification

        if (notifications) {
          notifications.forEach((notification, index) => {
            gsap.fromTo(
              notification,
              { opacity: 0, x: '-100%' },
              {
                opacity: 1,
                x: '0%',
                duration: 0.5,
                onComplete: () => {
                  if (index === 0) {
                    setTimeout(() => {
                      this.removeNotification()
                    }, 3000)
                  }
                }
              }
            )
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-container {
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 99999999999999999;
  border-radius: 10px;
}

.notification {
  padding: 10px 20px;
  background-color: $accent;
  color: $white;
  margin-bottom: 10px;
}
</style>
