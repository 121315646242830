<template>
  <label
    class="v-input__label"
    :for="attrs.id"
    tabindex="-1"
  >
    <p
      v-if="attrs.label"
      class="v-input__label-text"
    >
      {{ attrs.label }}
      <slot name="label_append" />
    </p>

    <div class="v-input__wrapper">
      <div
        class="v-input__wrapper-container"
        :class="{
          'input--error': hasErrors,
        }"
      >
        <input
          :id="attrs.id"
          v-maska="attrs?.maska"
          tabindex="0"
          class="v-input__wrapper-input"
          :class="attrs.id"
          :placeholder="attrs.placeholder"
          :style="inputStyles"
          :maxlength="attrs.v_maxlength"
          :value="modelValue"
          :disabled="attrs.disabled"
          :type="attrs?.type"
          @change="updateModelValue($event.target.value)"
          @focus="focusHandler"
        >
        <div
          v-if="showSuggestions"
          class="custom-input-suggestions"
        >
          <div
            v-for="(suggest, idx) in suggestions"
            :key="idx"
            class="custom-input-suggestions-item"
            @click="selectSuggest($event, suggest)"
          >
            {{ suggestValue(suggest) }}
          </div>
        </div>
        <v-icon
          v-show="focus && clear"
          class="v-input__clear"
          :class="{ 'v-input__clear--error': hasErrors }"
          :icon="require(`@/assets/svg/input_clear_icon.svg`)"
          @click="clearInput"
        />
      </div>
      <p
        v-show="errorText"
        ref="errors"
        class="v-input__error"
      >
        {{ errorBag?.[0] }}
      </p>
      <p
        ref="serverErrors"
        class="v-input__error v-input__serverError"
      >
        {{ serverErrorBag?.[0] }}
      </p>
      <slot name="inner" />
    </div>
  </label>
</template>

<script>
import gsap from 'gsap'
import VIcon from '@/components/UI/v-icon.vue'

export default {
  name: 'VInput',
  components: { VIcon },
  props: {
    modelValue: { type: String, default: null },
    attrs: {
      type: Object,
      default: () => ({})
    },
    errorBag: {
      type: Array,
      default: () => ([])
    },
    serverErrorBag: {
      type: Array,
      default: () => ([])
    },
    suggestions: {
      type: Array,
      default: () => ([])
    },
    clear: {
      type: Boolean,
      default: true
    },
    errorText: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'selectSuggest'],
  data () {
    return {
      focus: false,
      config: {
        attributes: true,
        childList: true,
        subtree: true
      },
      observer: new MutationObserver(this.callback)
    }
  },
  computed: {
    hasErrors () {
      return this.errorBag.length > 0 || this.serverErrorBag.length > 0
    },
    inputStyles () {
      return {
        'max-width': this.attrs.width + 'px',
        height: this.attrs.height + 'px',
        'padding-right': this.focus ? '51px' : null
      }
    },
    showSuggestions () {
      return (
        this.focus &&
        this.modelValue?.length > 1 &&
        this.suggestions?.length > 0
      )
    }
  },
  mounted () {
    this.observer.observe(this.$refs.errors, this.config)
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    selectSuggest (evt, suggest) {
      // console.log(this.focus);
      setTimeout(() => {
        this.focus = false
      }, 100)
      this.$emit('selectSuggest', { field: this, suggest })
    },
    suggestValue (suggest) {
      return this.attrs.id === 'organization'
        ? suggest?.data?.address?.value
        : suggest.value
    },
    callback (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          gsap.fromTo(
            this.$refs.errors,
            { opacity: 0, transition: 1000 },
            { opacity: 1, transition: 1000 }
          )
        } else if (mutation.type === 'attributes') {
        }
      }
    },
    clearInput () {
      this.$emit('update:modelValue', '')
      this.$el.control.value = ''
    },
    focusHandler () {
      this.focus = true
    },
    handleClickOutside (event) {
      if (!this.$el.contains(event.target)) {
        this.focus = false
      }
    },
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.v-input {
  &__label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0;
    }
  }

  // .v-input__label-text

  &__label-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    color: $black;
    margin-bottom: 15px;
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
  }

  // .v-input__wrapper

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  // .v-input__wrapper-container

  &__wrapper-container {
    // min-width: 280px;
    border-radius: 50px;
    width: 100%;
    position: relative;
  }

  // .v-input__clear

  &__clear {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
  }

  // .v-input__wrapper-input

  &__wrapper-input {
    background-color: $silver;
    border-radius: 50px;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 10px 20px;
    width: 100%;
    font-size: 16px;
    line-height: 1.4;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    &::placeholder {
      color: $grayish;
    }
  }

  // .v-input__eye

  &__eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  // .v-input__error

  &__error {
    font-family: "Inter";
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    max-width: 100%;
    white-space: break-spaces;
    span,
    a {
      color: inherit;
      border-bottom: 1px solid $red;
      padding-bottom: 1px;
    }
  }
}
.custom-input-suggestions {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: $sec-grey;
  z-index: 3;
  width: 100%;
  max-height: 300px;
  font-size: 16px;
  border-radius: 5px;
  overflow-y: auto;
  padding: 5px 0;
  font-family: "Lato", sans-serif;
  //   &::-webkit-scrollbar {
  //   width: 5px;
  //   background-color: $secondRed;
  // }
  // &::-webkit-scrollbar-track {
  //   background: $thirdGray;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: $seventhGray;
  //   border: 3px solid $secondRed;
  // }
}
.custom-input-suggestions::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  background-color: red;
}
.custom-input-suggestions::-webkit-scrollbar-track {
  background: $grey;
}
.custom-input-suggestions::-webkit-scrollbar-thumb {
  background-color: $text-black;
}
.custom-input-suggestions-item {
  padding: 6px 15px;
  font-size: inherit;
  line-height: 130%;
  text-align: left;
  transition: 0.3s ease;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: #838383;
  }
}
.v-inputerror {
}
.input--error {
  border: 1px solid $red !important;
}

@media (max-width: 1600px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
}
</style>
