import apiRequest from '../../middlewares/api-request'
import axios from 'axios'

const signup = {
  state: {
    csrf: '',
    token: '',
    user_id: '',
    cards: [],
    step: 0,
    availableFileTypes: [],
  },
  mutations: {
    SET_CSRF_TOKEN: (state, res) => {
      state.csrf = res
    },
    SET_CLIENT_ID: (state, res) => {
      state.user_id = res.data.data.client_id
    },
    SET_CARD: (state, { data }) => {
      state.cards.push(data.card)
      state.cards = [...new Set(state.cards.map(JSON.stringify))].map(
        JSON.parse
      )
    },
    SET_STEP: (state, { data }) => {
      state.step = data.data['step_data'] ? data.data['step_data']['step_value'] : null
    },
    SET_FILE_TYPES: (state, { data }) => {
      state.availableFileTypes = data.data['required_files']
    },
  },
  actions: {
    async CREATE_USER ({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {

        const res = axios.post(
          `${process.env.VUE_APP_API_URL}api/clients/store`,
          payload
        )

        if (res?.status === 422) {
          this.dispatch('addNotification', {message: res?.data?.message})
        }
        if (res?.data?.code === 200) {
          commit('SET_CLIENT_ID', res)
        }
        resolve(res)
      })
    },
    async UPDATE_USER ({ _, state }, { client, fields }) {
      const sendData = {}

      fields.forEach( f => {
        sendData[f['relation_field']['relation']] = {
          ...sendData[f['relation_field']['relation']],
          [f['relation_field']['column'] || f.id]: f.value
        }
      })

      return await apiRequest.function(
        'PATCH',
        `api/clients/${client}/update?step=${state.step}`,
        ['SET_STEP'],
        null,
        sendData,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_PASS ({ commit }, { fields }) {
      return new Promise((resolve) => {
        const res = axios.patch(
          `${process.env.VUE_APP_API_URL}api/clients/passport_data`,
          fields,
          {
            headers: {
              Authorization: 'Bearer ' + this.getters.TOKEN
            }
          }
        ).catch((error) => {
          if (error.response.status == 422) {
            this.dispatch('addNotification', { message: error.response.data.message })
          }
          resolve(error.response)
        })

        resolve(res)
      })

      return await apiRequest.function(
        'PATCH',
        'api/clients/passport_data',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_ADDRESS ({ commit }, { fields }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}api/clients/address`, fields, {
            headers: {
              Authorization: 'Bearer ' + this.getters.TOKEN
            }
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            resolve(error.response)
          })
      })

      return await apiRequest.function(
        'POST',
        'api/clients/address',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async UPLOAD_PHOTO ({ commit }, { files }) {
      const res = await apiRequest.function(
        'POST',
        'api/files/add',
        null,
        null,
        files,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.TOKEN}`
        }
      )

      return res
    },
    async UPLOAD_PHOTO_AUTH ({ commit }, { fields }) {
      const res = await apiRequest.function(
        'POST',
        'api/files/append/auth',
        null,
        null,
        fields,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.TOKEN}`
        }
      )

      return res
    },
    async DELETE_PHOTO ({ commit }, id) {
      if (id !== '') {
        const res = await apiRequest.function(
          'DELETE',
          `api/files/delete/${id}/auth`,
          null,
          null,
          null,
          {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.getters.TOKEN}`
          }
        )

        return res
      }

      return true
    },
    async REPLACE_PHOTO ({ commit }, { id, fields }) {
      const res = await apiRequest.function(
        'POST',
        `api/files/update/${id}/auth`,
        null,
        null,
        fields,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.TOKEN}`
        }
      )

      return res
    },
    async SEND_JOB_DATA ({ commit }, { fields }) {
      return await apiRequest.function(
        'POST',
        'api/clients/createJobData',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_BIND_CARD_LINK ({ commit }, { referer, url = '' }) {
      return await apiRequest.function(
        'GET',
        `api/pay/link${url}?referer=${referer}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SET_CLIENT_SERVICES ({ commit }, { insurance }) {
      return await apiRequest.function(
        'POST',
        'api/clients/services',
        null,
        null,
        { dok: false, rejection_reason: false, insurance },
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_BIND_CARD_CALLBACK ({ commit }, { search, client }) {
      const res = await apiRequest.function(
        'POST',
        `api/pay/callback/best2pay${search}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res === undefined) return
      const { data, code } = res.data

      if (code === 200) {
        commit('SET_CARD', res.data)
      }

      return res
    },
    async SEND_BID ({ commit }, { fields, url = '' }) {
      return new Promise((resolve) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}api/orders/store${url}`, fields, {
            headers: {
              Authorization: 'Bearer ' + this.getters.TOKEN
            }
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            resolve(error)
          })
      })

      return await apiRequest.function(
        'POST',
        'api/orders/store',
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_STEP ({ commit }, client) {
      const res = await apiRequest.function(
        'GET',
        `api/clients/${client}/getStep`,
        ['SET_STEP'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res?.status === 404 || res?.data?.code === 404) {
        window.location.href = '/'
      }

      return res
    },
    async BID_VERIFY ({ commit }, payload) {
      const res = await apiRequest.function(
        'POST',
        'api/orders/verify',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      return res
    },
    async GET_SETTINGS ({ commit }, ident) {
      const res = await apiRequest.function(
        'GET',
        `api/settings/get`,
        ['SET_FILE_TYPES'],
        { 'ident': ident },
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res?.status === 404 || res?.data?.code === 404) {
        window.location.href = '/'
      }

      return res
    },
    async GET_LAST_REFERER ({}, client) {
      return await apiRequest.function(
        'GET',
        `api/clients/${client}/last_referer`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    }
  },
  getters: {
    CARDS (state) {
      return state.cards
    },
    STEP (state) {
      return state.step
    },
    AVAILABLE_FILE_TYPES (state) {
      return state.availableFileTypes
    },
  }
}

export default signup
