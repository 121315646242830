<template>
  <div
    class="default-layout default"
    :class="{ 'mobile': $store.getters.IS_MOBILE }"
  >
    <v-header />
    <div
      v-show="!routerViewLoaded"
      class="test"
    />
    <router-view />
    <v-footer />
  </div>
</template>
<script>
import VHeader from '@/components/UI/v-header.vue'
import VFooter from '@/components/UI/v-footer.vue'

export default {
  name: 'DefaultLayout',
  components: {
    VHeader,
    VFooter
  },
  data () {
    return { routerViewLoaded: false }
  },
  watch: {},
  mounted () {
    setTimeout(() => {
      this.routerViewLoaded = true
    }, 100)
  }
}
</script>
<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $white-pink;
  main {
    flex: 1 1 auto;
  }
}
.default {
}
</style>
