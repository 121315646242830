<template>
  <v-popup
    class="signin__dialog"
    :visible="true"
    @close-popup="$emit('close')"
  >
    <template #title>
      Вход в личный кабинет
    </template>
    <template #default>
      <p
        v-show="signInForm.step === 1"
        class="signin__dialog-descr"
      >
        На номер <span>{{ signInForm.fields.phone.value }}</span> был
        отправлен СМС код для входа в личный кабинет
      </p>
      <v-input
        v-model="signInForm.fields.phone.value"
        :attrs="signInForm.fields.phone.attrs"
        class="signin__dialog-inp"
        :error-bag="signInForm.fields.phone.errorBag"
      />
      <v-input
        v-model="signInForm.fields.password.value"
        :attrs="signInForm.fields.password.attrs"
        class="signin__dialog-inp"
        :error-bag="signInForm.fields.password.errorBag"
      />
      <p
        class="signin__dialog-reset"
        @click="resetPassword"
      >
        Восстановить пароль
      </p>
      <v-btn
        class="signin__dialog-btn"
        @click="submitForm"
      >
        Войти
      </v-btn>
    </template>
  </v-popup>
</template>

<script setup>
import { reactive, nextTick, computed, defineEmits } from 'vue'
import { required, validateMinLength } from '@/utils/validates'
import { toServerPhone } from '@/utils/converters'
import { useStore } from 'vuex'

import VInput from '@/components/UI/v-input.vue'
import VPopup from '@/components/UI/v-popup.vue'
import VBtn from '@/components/UI/v-btn.vue'

const emit = defineEmits(['close', 'submit', 'reset'])

const store = useStore()

const signInForm = reactive({
  step: 0,
  fields: {
    password: {
      value: '',
      attrs: {
        id: 'password',
        placeholder: 'Введите пароль',
        type: 'password',
        label: 'Пароль',
        maska: {
          mask: 'HHHHHHHHHHHHHHHHHHHHHHH',
          tokens: { H: { pattern: /[A-Z-a-z0-9 ]/ } }
        }
      },
      validate: {
        required: {
          fn: required,
          args: []
        }
      },
      errorBag: [],
      isDirty: false
    },
    phone: {
      value: '',
      attrs: {
        id: 'login_phone',
        label: 'Ваш номер телефона',
        placeholder: 'Введите номер',
        maska: {
          mask: '+7 (###) ###-##-##',
          pattern: /\D/g
        }
      },
      validate: {
        validateMinLength: {
          fn: validateMinLength,
          args: [18, 11]
        },
        required: {
          fn: required,
          args: []
        }
      },
      errorBag: [],
      isDirty: false
    }
  }
})

const phoneValid = computed(() => {
  const phone = signInForm.fields.phone

  return !phone.errorBag.length && phone.isDirty
})
const passwordValid = computed(() => {
  const password = signInForm.fields.password

  return !password.errorBag.length && password.isDirty
})

const resetPassword = () => {
  emit('reset')
}

const submitForm = async () => {
  signInForm.fields.phone.validate.required.fn(signInForm.fields.phone)
  signInForm.fields.phone.validate.validateMinLength.fn(signInForm.fields.phone, 18, 11)
  signInForm.fields.password.validate.required.fn(signInForm.fields.password)

  await nextTick()

  if (phoneValid.value && passwordValid) {
    const payload = {
      phone: toServerPhone(signInForm.fields.phone.value),
      password: signInForm.fields.password.value
    }

    const { data, status } = await store.dispatch('LOGIN', payload)

    emit('submit', { data, status })
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/styles/components/signin_dialog.scss';
</style>
